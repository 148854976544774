<template lang="pug">
  div
    .row.justify-content-center
      .col-11.header
        div(style="margin: 0px auto;width: 50%;")
          img.client_logo(:src="Logo" v-openlog)


    .main_box(style="display: none;")
      h3(class="text-center") Version {{ version }}
      b-form(class="form-horizontal" @submit.stop.prevent="saveUserData")
        b-form-input(class="w-100" type="text" v-model="name" placeholder="Nombre" readonly)
        b-form-input(class="w-100" type="text" v-model="last_name" placeholder="Apellido" readonly)
        b-form-input(class="w-100" type="text" v-model="user_id" placeholder="Cédula" readonly)
        b-form-input(class="w-100" type="text" v-model="phone_number" placeholder="Celular" readonly)
        b-form-input(class="w-100" type="text" v-model="branchId" placeholder="Sucursal" readonly)
        b-form-input(class="w-100" type="text" v-model="queueId" placeholder="Tramite" readonly)
        b-form-input(class="w-100" type="text" v-model="fuente" placeholder="Fuente" readonly)
        b-form-input(class="w-100" type="text" v-model="marcacion" placeholder="Marcacion" readonly)
        b-form-textarea(ref="crude" :value="rawB64" rows="4" max-rows="6" placeholder="Base 64 Crudo" @click="copyPaste")
</template>

<script>
// import api_requests from "@/store/actions";
import { mapActions, mapState } from "vuex";
import _get from "lodash/get";
import { getOneTimeToken } from "./../helpers/Meet";
import QS from "query-string";
import kuid from "kuid";
import LS from "../helpers/localStorage";
import { utf8_to_b64 } from "../utils/decoder";
import LogoColmedica from "@/assets/colmedica_logo.png";

export default {
  name: "ProfilePM",
  data() {
    return {
      room_id: kuid(),
      local_queue: {},
      queryString: {},
      tipide: LS.getItem("tipide") || "",
      name: LS.getItem("name_user") || "",
      last_name: LS.getItem("last_name_user") || "",
      user_id: LS.getItem("user_id") || "",
      phone_number: LS.getItem("phone_number") || "",
      e_mail: LS.getItem("e_mail") || "",
      branchId: LS.getItem("sucursal") || "",
      queueId: LS.getItem("tramite") || "",
      fuente: LS.getItem("fuente") || "",
      marcacion: LS.getItem("marcacion") || "",
      token_col: LS.getItem("token_col") || "",
      rawB64: LS.getItem("rawB64") || "",
      errorMessage: false,
      LogoColmedica,
      ma: null
    };
  },

  watch: {
    name(val) {
      LS.setItem("name_user", val);
    },
    last_name(val) {
      LS.setItem("last_name_user", val);
    },
    user_id(val) {
      LS.setItem("user_id", val);
    },
    phone_number(val) {
      LS.setItem("phone_number", val);
    },
    e_mail(val) {
      LS.setItem("e_mail", val);
    }
  },
  async created() {

    // Check if user is marked with Priority (Occasional cautious)
    console.log('VALIDANDO MARCA VALOR')
    this.ma = LS.getItem("marcacion")
    console.log(this.ma)
    if(this.ma == 1){
      await this.getSites();
    }

    // if (this.tramite_info) return this.redirectToNext();
    //FIXME: Fix them. Compare branch and tramite. If equal, continue with current turn. If are different, delete previous turn and create new turn.
    // if (this.turn) this.redirectToNext();
    this.queryParser();
    if (this.$route.params.havedt) {
      this.saveUserData();
      // setTimeout(() => this.saveUserData(), 10000);
    }
    // else{
    //   return window.history.back();
    // }
  },

  methods: {
    ...mapActions({
      setTramite: "setTramite",
      setTurnUUID: "setTurnUUID",
      putLog: "log/put",
      getSites: "virtualrow/getSites",
    }),
    queryParser() {
      this.queryString = QS.parse(location.href.split("?")[1]);
    },
    copyPaste() {
      this.$refs.crude.select();
      document.execCommand("copy");
    },
    async saveUserData() {
      try {
        if (!this.turn) {
          await this.setTurnUUID();
          var client_properties = {
            dni: this.user_id,
            cuil: this.sessionMaster,
            cuit: this.tipide,
            cedulaIdentidad: this.sessionMaster, // this.user_id,
            // email: this.e_mail,
            firstName: this.name,
            lastName: this.last_name,
            phone: this.phone_number,
            roomId: this.room_id, // For Brilliant Use. delete before chime request
            token:
              this.env.VUE_APP_ENV == "production"
                ? this.token_col
                : await getOneTimeToken(), // For Brilliant Use. delete before chime request
            extraFields: [
              {
                showable: [
                  {
                    in: "workstation",
                    format: "both"
                  }
                ],
                Videollamada: `<a href="${location.origin}/#/turn_assigner/${
                  this.turnUUID
                }?${
                  this.dclient_data
                    ? "&pr=" +
                      utf8_to_b64(
                        JSON.stringify({
                          ...this.dclient_data,
                          position: this.position
                        })
                      )
                    : ""
                }" target="_blank">Presiona aquí</a>`
              }
            ]
          };

          this.$store.commit("setState", {
            key: "client_data",
            value: client_properties
          });

        // Check if user is marked with Priority (Occasional cautious)
          if(this.ma == 1){
            console.log(this.sites)
            console.log(this.branch_id)
            let sit = this.sites.find(s => s.branch_id ==  this.branch_id.branch_id);
            console.log(sit)
            if(sit?.queueList){
              let tr = sit.queueList.find(q => q.tramite_id == LS.getItem("tramite"))
              console.log(LS.getItem("tramite"))
              console.log(tr)
              if(tr?.cauto_tramite_id){
                this.queueId = tr.cauto_tramite_id
              }
            }
          }
      
          await this.setTramite({
            queueId: this.queueId,
            branchId: this.branchId,
            params: client_properties
          });
          this.putLog({
            name:
              "ProfilePM. Almacenamiento de información para generar el turno."
          });
        }
        // For demo. Route from data push
        // TODO: This action can change with correct paramethers of postmessage
        this.redirectToNext();
      } catch (error) {
        this.putLog({
          name:
            "ProfilePM. Error restrictivo. Falló la verificación de la compañía y la sucursal o la recolección de información para generar el turno.",
          checked: false,
          message: error
        });
        this.errorMessage = true;
        setTimeout(() => {
          this.errorMessage = false;
        }, 3000);
        throw error;
      }
    },
    redirectToNext() {
      this.$router.push({ name: "BeforeTurn" });
    },
    cleanCache() {
      localStorage.clear();
      this.name = "";
      this.last_name = "";
      this.user_id = "";
      this.phone_number = "";
      this.e_mail = "";
    }
  },

  computed: {
    ...mapState({
      version: state => state.version,
      position: state => state.position,
      sessionMaster: state => state.sessionMaster,
      tramite_info: state => state.tramite_info,
      company: state => state.company,
      this_user_data: state => state.client_data,
      queues: state => state.company_queue,
      turn: state => state.turn,
      turnUUID: state => state.turnUUID,
      c_data: state => state.client_data,
      eclient_data: state => state.eclient_data,
      dclient_data: state => state.dclient_data,
      env: state => state.env,
      sites: state => state.virtualrow.sites,
      branch_id: state => state.branchInfo,
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    },
    companyName() {
      return _get(this.company, "name");
    }
  }
};
</script>
<style scoped>
.main_box {
  width: 75vw;
  margin: auto;
  font-size: 1.4em;
}
.header {
  text-align: center;
}
.form-horizontal .form-control {
  color: #222;
  background-color: transparent;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 1px;
  width: calc(100% - 40px);
  height: 33px;
  padding: 3px 10px 0 0;
  box-shadow: none;
  border: none;
  border-radius: 0;
  display: inline-block;
  transition: all 0.3s;
}
.form-horizontal textarea.form-control {
  height: auto;
  width: 100%;
}
.form-horizontal .form-control::placeholder {
  color: #b5b5b5;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
}
.form-horizontal .form-control {
  background-color: rgba(255, 255, 255, 0.15);
  margin: 0 0 15px;
  border-bottom: 2px solid #e7e7e7;
}
.client_logo {
  width: 120px;
  height: auto;
}
.header {
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.submit_button {
  text-align: center;
}
</style>
